import { inject, Injectable } from '@angular/core';
import { CrmDictionary } from 'common-module/core/types';
import { CrmEndpoint, CrmEndpointDecorator } from 'common-module/endpoint';
import { compact } from 'lodash-es';
import { of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { selectDocumentTemplateFn } from '~/shared/modal/document/select-template/select-document-template';
import { saveFile } from '~/shared/utils/file/save-file';

import { DocumentsTemplatesApiService } from '../documents/documents-templates-api.service';

import { PatientModel } from './patient.model';

@Injectable({ providedIn: 'root' })
export class PatientsApiService {
  @CrmEndpointDecorator({
    configName: 'crm',
    endpointName: 'patients',
  })
  protected readonly endpoint!: CrmEndpoint<PatientModel>;

  private templatesApiService = inject(DocumentsTemplatesApiService);

  private selectTemplate = selectDocumentTemplateFn();

  get(id: string) {
    return this.endpoint.read(id);
  }

  create<Body>(body: Body) {
    return this.endpoint.create(body);
  }

  update<Body>(id: string, body: Body) {
    return this.endpoint.update(id, body);
  }

  list<Params>(params: Params) {
    return this.endpoint.list({ params });
  }

  listData<Params>(params: Params) {
    return this.list(params).pipe(map(({ data }) => data));
  }

  listAll<Params>(params: Params) {
    return this.endpoint.listAll({ params });
  }

  search(params: CrmDictionary, initial?: string) {
    return this.list(params).pipe(
      switchMap(({ data }) => {
        if (!initial) {
          return of(data);
        }

        const found = data.find(({ _id }) => _id === initial);

        if (found) {
          return of(data);
        }

        return this.get(initial).pipe(
          map((patient) => compact([patient, ...data])),
          catchError(() => of(data)),
        );
      }),
    );
  }

  resolvePatient(patient?: string | PatientModel) {
    if (!patient) {
      return throwError(() => new Error('Undefined patient to resolve!'));
    }

    if (typeof patient === 'string') {
      return this.get(patient).pipe(
        catchError(() =>
          throwError(() => new Error(`Patient with ID: ${patient} not found!`)),
        ),
      );
    }

    return of(patient);
  }

  resolvePatientWithUndefinedOnError(patient?: string | PatientModel) {
    return this.resolvePatient(patient).pipe(catchError(() => of(undefined)));
  }

  downloadTimeline(patient: PatientModel): void {
    this.selectTemplate('timeline')
      .pipe(
        switchMap((template) => {
          return this.templatesApiService.downloadDocument(template._id, {
            load: { timeline: patient._id },
          });
        }),
      )
      .subscribe((response) => {
        saveFile(response, 'application/pdf', `${patient.name}_timeline.pdf`);
      });
  }
}
